import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Form from './common'

import { editSubcontractorModal, editSubcontractor } from 'store/subcontractors/actions'

function index({ subcontractor: target }) {
  let dispatch = useDispatch()
  const { edit: modal } = useSelector((state) => state.subcontractors);
  let [subcontractor, setSubcontractor] = useState()

  useEffect(() => {
    if (modal) {
      setSubcontractor(target)
    }
  }, [modal])

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id_no: (subcontractor && subcontractor.id_no) || '',
      first_name: (subcontractor && subcontractor.first_name) || '',
      last_name: (subcontractor && subcontractor.last_name) || '',
      profession: (subcontractor && subcontractor.profession) || '',
      company_name: (subcontractor && subcontractor.company_name) || '',
      business_phone: (subcontractor && subcontractor.business_phone) || '',
      city: (subcontractor && subcontractor.city) || '',
      state: (subcontractor && subcontractor.state) || '',
      zip_code: (subcontractor && subcontractor.zip_code) || '',
      country: (subcontractor && subcontractor.country) || '',
      email: (subcontractor && subcontractor.user?.email) || '',
      address: (subcontractor && subcontractor.address) || '',
      avatar: (subcontractor && subcontractor.avatar) || null,
      files: (subcontractor && subcontractor.files) || []
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("Please Enter Your First Name"),
      last_name: Yup.string().required("Please Enter Your Last Name"),
      company_name: Yup.string().required("Please Enter Your Company Name"),
      business_phone: Yup.string().required("Please Enter Your Business Phone"),
      city: Yup.string().required("Please Enter Your City"),
      email: Yup.string().required("Please Enter Your Email"),
      profession: Yup.mixed().required("Tell us about your profession"),
      state: Yup.string().required("Please Enter Your State"),
      zip_code: Yup.string().required("Please Enter Your Zip code"),
      address: Yup.string().required("Please Enter Your Address"),
      country: Yup.string().required("Please Enter Your Country"),
    }),
    onSubmit: (_values, actions) => {
      let { email, ...values } = _values
      dispatch(editSubcontractor(subcontractor.id, { ...values, user: { email } }, actions))
    },
  });



  return (
    <React.Fragment>
      <Modal size="lg" isOpen={modal} toggle={() => dispatch(editSubcontractorModal())}>
        <ModalHeader tag="h4" toggle={() => dispatch(editSubcontractorModal())}>
          Edit Subcontractor
        </ModalHeader>
        <ModalBody>
          <Form
            validation={validation}
            target={target}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default index
