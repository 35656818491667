import React, { useEffect, useState } from 'react'
import { GoogleMap, Marker, useJsApiLoader, Circle } from '@react-google-maps/api'
import Loader from 'common/Loader'
const { REACT_APP_GOOGLE_KEY } = process.env;
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';

import {
  Input, Label,
  Row, Col
} from "reactstrap";
import { toast } from 'react-toastify';

function Maps({ loaded, form }) {
  const [center, setCenter] = useState({ lat: 0, lng: 0 }); // Default location
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    libraries: ['places', 'geocoding'],
    googleMapsApiKey: REACT_APP_GOOGLE_KEY,
  })
  const [map, setMap] = useState(null)

  useEffect(() => {
    if (!loaded || !map || !form?.values)
      return

    if (!!form.values.google_place_id) {
      setCenter({
        lat: form.values.latitude,
        lng: form.values.longitude
      })
      return
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCenter({ lat: latitude, lng: longitude });
        },
        () => {
          alert("Failed to fetch your location.");
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }, [loaded, map, form]);

  const onLoad = React.useCallback(function callback(map) {
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  // const handleMapClick = (event) => {
  //   // Update marker position to the clicked location
  //   setCenter({
  //     lat: event.latLng.lat(),
  //     lng: event.latLng.lng(),
  //   });
  // };

  return isLoaded ? (
    <div>
      <Row className='mb-4'>

        <Col xs="6">
          <GooglePlacesAutocomplete
            apiKey={REACT_APP_GOOGLE_KEY}
            selectProps={{
              defaultInputValue: form.values.project_address,
              placeholder: "Search for a place...", onChange: e => {
                geocodeByPlaceId(e.value.place_id)
                  .then(results => {
                    let location = results[0].geometry.location
                    setCenter({
                      lat: location.lat(),
                      lng: location.lng(),
                    });

                    form.setValues({
                      ...form.values,
                      longitude: location.lng(),
                      latitude: location.lat(),
                      google_place_id: e.value.place_id,
                      project_address: results[0].formatted_address
                    })
                  })
                  .catch(_ => toast.error("Something went wrong, please try again!"));
              }
            }}
          />
          <span className="text-danger font-size-11">{form.errors.google_place_id}</span>
        </Col>

        <Col xs="6">
          <Label className="form-label">Adjust Radius</Label>
          <Input
            min={10}
            value={form.values.radius}
            max={100}
            onChange={e => form.setFieldValue("radius", Number(e.target.value))}
            type="range"
          />
        </Col>
      </Row>

      <GoogleMap
        mapContainerStyle={{
          height: '400px',
          width: '100%'
        }}
        // onClick={handleMapClick} 
        center={center}
        onLoad={onLoad}
        onUnmount={onUnmount}
        zoom={18}
      >
        <Circle
          center={center}
          radius={form.values.radius}
          options={{
            fillColor: "blue",
            fillOpacity: 0.2,
            strokeColor: "blue",
            strokeOpacity: 0.5,
            strokeWeight: 1,
          }}
        />

        <Marker position={center} />
      </GoogleMap>
    </div>
  ) : (
    <Loader message='Please wait' />
  )
}

export default React.memo(Maps)
