import React, { useEffect } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row
} from "reactstrap";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import bootstrapPlugin from '@fullcalendar/bootstrap'
import listPlugin from '@fullcalendar/list'

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

//redux
import { useSelector, useDispatch } from "react-redux";
import { setLoader } from "utils/common";
import { getPunches } from "store/actions";
import moment from "moment";

const Calender = () => {
  const dispatch = useDispatch();

  const { punches: events, loading } = useSelector(state => ({
    punches: state.projects.punches.map(_event => ({
      id: _event.id,
      title: `${_event.user.profile.first_name} ${_event.user.profile.last_name} (${_event.project?.project_name || "N/A"})`,
      start: new Date(_event.date + "T" + _event.start_time),
      end: new Date(_event.date + "T" + _event.end_time)
    })),
    loading: state.projects.loading
  }));

  useEffect(() => {
    setLoader(loading);
  }, [loading]);

  function handleMonthChange({ startStr, endStr }) {
    dispatch(getPunches({ start_date: moment(startStr).format("YYYY-MM-DD"), end_date: moment(endStr).format("YYYY-MM-DD") }, true));
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Geofencing</title>
        </MetaTags>
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs breadcrumbItem="Geofencing" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      {/* fullcalendar control */}
                      <FullCalendar
                        plugins={[
                          dayGridPlugin,
                          timeGridPlugin,
                          bootstrapPlugin,
                          listPlugin
                        ]}
                        displayEventTime={false}
                        datesSet={handleMonthChange}
                        handleWindowResize={true}
                        themeSystem="bootstrap"
                        headerToolbar={{
                          center: "title",
                          right: 'today,prev,next',
                          left: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                        }}
                        buttonText={{
                          today: 'Today',
                          month: 'Month',
                          week: 'Week',
                          day: 'Day',
                          list: 'List'
                        }}
                        events={events}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Calender.propTypes = {
  events: PropTypes.array,
  onGetEvents: PropTypes.func
};

export default Calender;
