import { filterParams } from "../apiHelper";
import request from "../restClient";

export const getProjects = async (params) => {
  var queryParams = new URLSearchParams(); //## query params supported

  Object.entries(params).forEach(keyValue => {
    if (keyValue[0] == "user_ids")
      keyValue[1].forEach(_item => {
        queryParams.append(keyValue[0], _item?.id);
      })
    else if (keyValue[1])
      queryParams.append(keyValue[0], keyValue[1]);
  })

  return await request({
    url: "project/",
    method: "GET",
    params: queryParams,
    cancelPrevious: params.cancelPrevious || true,
  });
};

export const getProjectHours = async (id, params) => {
  return await request({
    url: `project/${id}/work_hours/`,
    method: "POST",
    data: {
      period_type: params.type,
      start_date: params.start_date,
      end_date: params.end_date
    }
  });
};

export const getProject = async (id) => {
  return await request({
    url: `project/${id}/`,
    method: "GET"
  });
};

export const getProjectStats = async () => {
  return await request({
    url: `project/status_count/`,
    method: "GET"
  });
};

export const getProjectPayroll = async (params) => {
  return await request({
    url: "project/",
    method: "GET",
    params: filterParams(params),
  });
};

export const getPunches = async (params) => {
  return await request({
    url: "time_clock_punches/",
    method: "GET",
    params: filterParams(params),
  });
};

export const getInvoicesCount = async () => {
  return await request({
    url: "invoices/count/",
    method: "GET"
  });
};

export const deleteProject = async (id) => {
  return await request({
    url: `project/${id}/`,
    method: "DELETE"
  });
};

export const editProject = async (id, data) => {
  const formD = new FormData()
  Object.entries(data).forEach((keyValue) => {
    if (keyValue[0] == "customer")
      formD.append(keyValue[0], keyValue[1]?.id)
    else
      formD.append(keyValue[0], keyValue[1])
  })

  return await request({
    url: `project/${id}/`,
    method: "PATCH",
    headers: {
      'Content-type': 'multipart/form-data',
    },
    data: formD
  });
};

export const addProject = async (data) => {
  const formD = new FormData()
  Object.entries(data).forEach((keyValue) => {
    if (keyValue[0] == "customer")
      formD.append(keyValue[0], keyValue[1]?.id)
    else
      formD.append(keyValue[0], keyValue[1])
  })

  return await request({
    url: `project/`,
    method: "POST",
    headers: {
      'Content-type': 'multipart/form-data',
    },
    data: formD
  });
};
