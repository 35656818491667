import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  FormFeedback,
  UncontrolledTooltip,
} from "reactstrap";
import MetaTags from "react-meta-tags";
import Breadcrumbs from "components/Common/Breadcrumb";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { v4 as uuid } from 'uuid';
import AsyncSelect from 'react-select/async';
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import {
  getTasks as onGetTasks,
} from "store/tasks/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { map } from "lodash";
import Loader from "common/Loader";
import moment from "moment";
import { getProjects } from "api/v1";
import { toast } from "react-toastify";

const TasksComponent = () => {
  const dispatch = useDispatch();
  const [isSubmitted, setSubmitted] = useState(false)
  const { taskWithPagy, loading } = useSelector(state => ({
    taskWithPagy: state.tasks.taskWithPagy,
    loading: state.tasks.loading,
  }));
  let { results: tasks, total_hours = 0 } = taskWithPagy
  const [params, setParams] = useState({})

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      project: null,
      from_date: '',
      to_date: '',
    },
    validationSchema: Yup.object({
      from_date: Yup.string().required("Please Enter Start Date"),
      to_date: Yup.string().required("Please Enter End Date"),
      project: Yup.mixed().required("Please Select Project"),
    }),
    onSubmit: (values) => {
      setSubmitted(true)
      let { project, ..._values } = values
      setParams(_values)
      dispatch(onGetTasks(null, true, { ..._values, page_size: 1000, project_id: project.id }))
    },
  });
  //pagination customization
  const pageOptions = {
    sizePerPage: taskWithPagy.page_size,
    totalSize: taskWithPagy.total,
    page: taskWithPagy.page,
    custom: true,
  };

  const COLS = [
    {
      text: "id",
      dataField: "id",
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <>{row.id}</>,
    },
    {
      text: "#",
      dataField: "index",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row, rowIndex) => <>{rowIndex + 1}</>,
    },
    {
      dataField: "task",
      text: "Task name",
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1">{row.task}</p>
        </>
      ),
    },
    {
      dataField: "start_date",
      text: "Start Date",
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1">{handleValidDate(params.from_date)}</p>
        </>
      ),
    },
    {
      dataField: "end_date",
      text: "End Date",
      formatter: (cellContent, row) => {console.log(row); return(
        <>
          <p className="mb-1">{handleValidDate(params.to_date)}</p>
        </>
      )},
    },
    {
      dataField: "estimated_hours",
      text: "Estimated Time",
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1">{`${row.estimated_hours || 0} Hours`}</p>
        </>
      ),
    },
    {
      dataField: "total_hours",
      text: "Working Time",
      formatter: (cellContent, row) => (
        <>
          <p className="mb-1">{`${row.total_hours} Hours`}</p>
        </>
      ),
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cellContent, row) => (
        <p className="mb-1">{`${row.status}`}</p>
      ),
    },
    {
      dataField: 'custom',
      text: "Members",
      sort: false,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        return (
          <div className="avatar-group">
            {map(row.users, (_member, index) => {
              let { profile: member } = _member
              let _uuid = uuid()
              return (
                !member.avatar || member.avatar !== "Null" && (
                  <div
                    className="avatar-group-item"
                    key={_uuid}
                  >
                    <Link
                      to="#"
                      className="team-member d-inline-block"
                      id={`tooltip-${_uuid}`}
                    >
                      <img
                        src={member.avatar}
                        className="rounded-circle avatar-xs"
                        alt=""
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target={`tooltip-${_uuid}`}
                      >
                        {`${member.first_name} (${_member.type})`}
                      </UncontrolledTooltip>
                    </Link>
                  </div>
                )
              )
            })}
          </div>
        )
      },
    }
  ];

  let loadProjects = (search) => {
    return getProjects({ search, no_paginate: 1 })
      .then((results = []) => {
        return results
      })
      .catch(({ message }) => {
        if (message !== 'request-cancel') {
          toast.error(message);
        }
      })
  }

  var node = useRef();

  /** set Date formate */
  const handleValidDate = date => {
    const date1 = date ? moment(date, "YYYY-MM-DD").format("DD MMM Y") : "N/A";
    return date1;
  };

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page }) => {
    const { values: { project, ...values } } = validation
    setParams(values)
    if (type == "pagination")
      dispatch(onGetTasks(null, true, { page, page_size: 1000, ...values, project_id: project.id }));
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  return (
    <React.Fragment>
      <div className={"page-content"}>
        <MetaTags>
          <title>Task Reports</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Task Reports" />
          <Row>
            <Col xs="12">
              <Card style={{ borderWidth: 1, borderColor: '#F1F1F1' }}>
                <CardHeader className="bg-white pt-3">
                  <Row>
                    <Col xs="10">
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}>
                        <div className="d-flex align-items-start">
                          <div>
                            <Label className="form-label">From Date</Label>
                            <Input
                              name="from_date"
                              type="date"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.from_date || ""}
                              invalid={
                                validation.touched.from_date && validation.errors.from_date ? true : false
                              }
                            />
                            {validation.touched.from_date && validation.errors.from_date ? (
                              <FormFeedback type="invalid">{validation.errors.from_date}</FormFeedback>
                            ) : null}
                          </div>

                          <div className="mx-2">
                            <Label className="form-label">To Date</Label>
                            <Input
                              name="to_date"
                              type="date"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.to_date || ""}
                              invalid={
                                validation.touched.to_date && validation.errors.to_date ? true : false
                              }
                            />
                            {validation.touched.to_date && validation.errors.to_date ? (
                              <FormFeedback type="invalid">{validation.errors.to_date}</FormFeedback>
                            ) : null}
                          </div>

                          <div style={{ minWidth: '13rem' }}>
                            <Label className="form-label">Project</Label>
                            <AsyncSelect
                              defaultOptions
                              placeholder="Select Project"
                              required
                              value={validation.values.project}
                              getOptionLabel={({ project_name }) => `${project_name}`}
                              getOptionValue={({ id }) => id}
                              loadOptions={loadProjects}
                              onChange={(_project) => {
                                validation.setFieldValue('project', _project)
                              }}

                            />
                            {validation.touched.project && validation.errors.project ? (
                              <span className="text-danger font-size-11">{validation.errors.project}</span>
                            ) : null}
                          </div>
                        </div>

                        <Button
                          type="submit"
                          color="success"
                          className="btn-rounded mt-1"
                        >
                          <i className="bx bx-search-alt me-1" />
                          Generate
                        </Button>
                      </Form>
                    </Col>

                    <Col xs="2" className="text-end">
                      <h5>Total Hours: {total_hours}</h5>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  {
                    loading &&
                    <Loader message={"Please wait"} />
                  }

                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={COLS}
                    data={(!loading && isSubmitted) ? tasks : []}
                  >
                    {({ paginationProps, paginationTableProps }) => {
                      return (
                        <ToolkitProvider
                          keyField="id"
                          data={(!loading && isSubmitted) ? tasks : []}
                          columns={COLS}
                          bootstrap4
                          search
                        >
                          {toolkitProps => {
                            return (
                              <div className={loading ? "d-none" : 'd-block'}>
                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        noDataIndication={isSubmitted ? "No Data found" : "Please select the filter to generate report"}
                                        defaultSorted={defaultSorted}
                                        classes={"table align-middle table-nowrap"}
                                        keyField="id"
                                        remote={{ search: true, pagination: true }}
                                        {...toolkitProps.baseProps}
                                        onTableChange={handleTableChange}
                                        {...paginationTableProps}
                                        ref={node}
                                      />
                                    </div>

                                  </Col>
                                </Row>
                                {/* <Row className="align-items-md-center mt-30 px-2">
                                  <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </Col>
                                </Row> */}
                              </div>
                            )
                          }}
                        </ToolkitProvider>
                      )
                    }}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment >
  );
};

TasksComponent.propTypes = {
  tasks: PropTypes.array,
  onGetTasks: PropTypes.func,
  onAddNewTask: PropTypes.func,
  onDeleteTask: PropTypes.func,
  onUpdateTask: PropTypes.func,
};

export default TasksComponent;
