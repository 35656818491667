import { filterParams } from "../apiHelper";
import request from "../restClient";

export const getSubcontractors = async (params) => {
  return await request({
    url: "subcontractors/",
    method: "GET",
    params: filterParams(params),
    cancelPrevious: params.cancelPrevious || true,
  });
};

export const deleteSubcontractor = async (id) => {
  return await request({
    url: `subcontractors/${id}/`,
    method: "DELETE"
  });
};


export const deleteSubcontractorFile = async (subcontractorId, fileId) => {
  return await request({
    url: `subcontractors/${subcontractorId}/files/${fileId}/`,
    method: "DELETE"
  });
};

export const editSubcontractor = async (id, data) => {
  const formD = new FormData()
  Object.entries(data).forEach((keyValue) => {
    if (keyValue[0] == 'user')
      formD.append(keyValue[0], JSON.stringify(keyValue[1]))
    else if (keyValue[0] == 'id_no') {
      if (keyValue[1])
        formD.append(keyValue[0], keyValue[1])
    }
    else if (keyValue[0] == 'files')
      keyValue[1].filter(file => !file.id).forEach(file => {
        formD.append(keyValue[0], file)
      })
    else if (keyValue[0] == "avatar") {
      if (keyValue[1] && typeof (keyValue[1]) != "string")
        formD.append(keyValue[0], keyValue[1])
    }
    else
      formD.append(keyValue[0], keyValue[1])
  })

  return await request({
    url: `subcontractors/${id}/`,
    method: "PATCH",
    headers: {
      'Content-type': 'multipart/form-data'
    },
    data: formD
  });
};

export const addSubcontractor = async (data) => {
  const formD = new FormData()
  Object.entries(data).forEach((keyValue) => {
    if (keyValue[0] == 'user')
      formD.append(keyValue[0], JSON.stringify(keyValue[1]))
    else if (keyValue[0] == 'id_no') {
      if (keyValue[1])
        formD.append(keyValue[0], keyValue[1])
    }
    else if (keyValue[0] == 'files')
      keyValue[1].filter(file => !file.id).forEach(file => {
        formD.append(keyValue[0], file)
      })
    else if (keyValue[0] == "avatar") {
      if (keyValue[1] && typeof (keyValue[1]) != "string")
        formD.append(keyValue[0], keyValue[1])
    }
    else
      formD.append(keyValue[0], keyValue[1])
  })

  return await request({
    url: `subcontractors/`,
    method: "POST",
    headers: {
      'Content-type': 'multipart/form-data',
    },
    data: formD
  });
};
