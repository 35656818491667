import {
  GET_PROJECTS_FAIL,
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS,
  ADD_PROJECT,
  EDIT_PROJECT,
} from "./actionTypes";

const INIT_STATE = {
  projectWithPagy: {
    total: 0,
    page: 1,
    page_size: 10,
    results: []
  },
  new: false,
  edit: false,
  delete: false,
  loading: false,
  hoursLoading: false,
  projectDetail: null,
  projectHours: [],
  punches: []
};

const projects = (state = INIT_STATE, action) => {
  switch (action.type) {
    case 'GET_PROJECT':
      return {
        ...state,
        projectDetail: null,
        new: false,
        edit: false,
        delete: false,
        loading: action.payload.loading
      };

    case GET_PROJECTS:
      return {
        ...state,
        new: false,
        edit: false,
        delete: false,
        loading: action.payload.loading
      };

    case 'GET_PROJECTS_HOURS':
      return {
        ...state,
        hoursLoading: action.payload.loading
      };

    case 'SET_PROJECT_DETAIL':
      return {
        ...state,
        loading: false,
        projectDetail: action.payload
      };

    case 'SET_PROJECT_HOURS':
      return {
        ...state,
        hoursLoading: false,
        projectHours: action.payload
      };

    case GET_PROJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        projectWithPagy: action.payload,
      };

    case ADD_PROJECT:
      return {
        ...state,
        loading: true
      };

    case EDIT_PROJECT:
      return {
        ...state,
        loading: true
      };

    case GET_PROJECTS_FAIL:
      return {
        ...state,
        loading: false,
        hoursLoading: false,
        error: action.payload,
      };

    case "NEW_PROJECT_MODAL":
      return {
        ...state,
        new: !state.new
      };

    case "DELETE_PROJECT_MODAL":
      return {
        ...state,
        delete: !state.delete
      };

    case "EDIT_PROJECT_MODAL":
      return {
        ...state,
        edit: !state.edit
      };

    case "GET_PUNCHES":
      return {
        ...state,
        loading: action.payload.loading
      };

    case 'GET_PUNCHES_SUCCESS':
      return {
        ...state,
        loading: false,
        punches: action.payload,
      };
    default:
      return state;
  }
};

export default projects;
