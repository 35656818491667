import React from "react";
import {
  Col,
  Row,
  Input,
  FormFeedback,
  Label,
  Form,
} from "reactstrap";
import AsyncSelect from 'react-select/async';
import { getCustomers } from "api/v1";
import Maps from "components/Common/Maps";

function index({ validation, loaded }) {

  let loadCustomers = (search) => {
    return getCustomers({ search, no_paginate: 1 })
      .then((results = []) => {
        return results
      })
      .catch(({ message }) => {
        if (message !== 'request-cancel') {
          toast.error(message);
        }
      })
  }

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      <Row>
        <Col xs="12" className="mb-3">
          <Label className="form-label">Name</Label>
          <Input
            name="project_name"
            type="text"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.project_name || ""}
            invalid={
              validation.touched.project_name && validation.errors.project_name ? true : false
            }
          />
          {validation.touched.project_name && validation.errors.project_name ? (
            <FormFeedback type="invalid">{validation.errors.project_name}</FormFeedback>
          ) : null}
        </Col>

        <Col xs="12" className="mb-3">
          <Label className="form-label">Description</Label>
          <Input
            name="project_description"
            type="textarea"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.project_description || ""}
            invalid={
              validation.touched.project_description && validation.errors.project_description ? true : false
            }
          />
          {validation.touched.project_description && validation.errors.project_description ? (
            <FormFeedback type="invalid">{validation.errors.project_description}</FormFeedback>
          ) : null}
        </Col>

        <div className="mb-3">
          <Label className="form-label">Customer</Label>
          <AsyncSelect
            defaultOptions
            placeholder="Select Customer"
            required
            value={validation.values.customer}
            getOptionLabel={({ first_name, last_name }) => `${first_name} ${last_name}`}
            getOptionValue={({ id }) => id}
            loadOptions={loadCustomers}
            onChange={(_customer) => {
              validation.setFieldValue('customer', _customer)
            }}

          />
          {validation.touched.customer && validation.errors.customer ? (
            <span className="text-danger font-size-11">{validation.errors.customer}</span>
          ) : null}
        </div>

        <div className="mb-3">
          <Label className="form-label">Status</Label>
          <Input
            name="status"
            type="select"
            className="form-select"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.status || ""}
            invalid={
              validation.touched.status && validation.errors.status ? true : false
            }
          >
            <option value=""></option>
            <option value="Processing">Processing</option>
            <option value="To Do">To Do</option>
            <option value="Working">Working</option>
            <option value="Complete">Complete</option>
            <option value="Rejected">Rejected</option>

          </Input>
          {validation.touched.status && validation.errors.status ? (
            <FormFeedback type="invalid">{validation.errors.status}</FormFeedback>
          ) : null}
        </div>

        <Col xs="12" md="6" className="mb-3">
          <Label className="form-label">Start Date</Label>
          <Input
            name="project_start_date"
            type="date"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.project_start_date || ""}
            invalid={
              validation.touched.project_start_date && validation.errors.project_start_date ? true : false
            }
          />
          {validation.touched.project_start_date && validation.errors.project_start_date ? (
            <FormFeedback type="invalid">{validation.errors.project_start_date}</FormFeedback>
          ) : null}
        </Col>

        <Col xs="12" md="6" className="mb-3">
          <Label className="form-label">End Date</Label>
          <Input
            name="project_end"
            type="date"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.project_end || ""}
            invalid={
              validation.touched.project_end && validation.errors.project_end ? true : false
            }
          />
          {validation.touched.project_end && validation.errors.project_end ? (
            <FormFeedback type="invalid">{validation.errors.project_end}</FormFeedback>
          ) : null}
        </Col>

        <Col xs="12" className="mb-3">
          <Label className="form-label font-weight-bold">Select Location</Label>

          <Maps form={validation} loaded={loaded} />
        </Col>

        <Col xs="12" className="mb-3">
          <Label className="form-label">Address</Label>
          <Input
            name="project_address"
            type="textarea"
            disabled
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.project_address || ""}
            invalid={
              validation.touched.project_address && validation.errors.project_address ? true : false
            }
          />
          {validation.touched.project_address && validation.errors.project_address ? (
            <FormFeedback type="invalid">{validation.errors.project_address}</FormFeedback>
          ) : null}
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="text-end">
            <button
              type="submit"
              className="btn btn-success save-customer"
            >
              Save
            </button>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default index
