import {
  GET_PROJECTS,
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_FAIL,
  DELETE_PROJECT,
  ADD_PROJECT,
  EDIT_PROJECT
} from "./actionTypes"

export const getProjects = (loading = false, params = {}) => ({
  type: GET_PROJECTS,
  payload: { loading, params }
})

export const getProjectHours = (id, loading = false, params = {}) => ({
  type: 'GET_PROJECTS_HOURS',
  payload: { id, loading, params }
})

export const getProject = (loading = false, id, history = null) => ({
  type: 'GET_PROJECT',
  payload: { loading, id, history }
})

export const getProjectsSuccess = projects => ({
  type: GET_PROJECTS_SUCCESS,
  payload: projects,
})

export const getProjectsFail = error => ({
  type: GET_PROJECTS_FAIL,
  payload: error,
})

export const setProjectDetail = projectDetail => {
  return ({
    type: 'SET_PROJECT_DETAIL',
    payload: projectDetail,
  })
}

export const setProjectHours = projectHours => {
  return ({
    type: 'SET_PROJECT_HOURS',
    payload: projectHours,
  })
}

export const deleteProject = project => ({
  type: DELETE_PROJECT,
  payload: { project }
})

export const addProject = (project, actions) => {
  return {
    type: ADD_PROJECT,
    payload: { project, actions },
  }
}

export const editProject = (id, project, actions = null) => {
  return {
    type: EDIT_PROJECT,
    payload: { id, project, actions },
  }
}

export const newProjectModal = () => ({
  type: 'NEW_PROJECT_MODAL'
})

export const deleteProjectModal = () => ({
  type: 'DELETE_PROJECT_MODAL'
})

export const editProjectModal = () => ({
  type: 'EDIT_PROJECT_MODAL'
})

// punches
export const getPunches = (params = {}, loading = false) => ({
  type: 'GET_PUNCHES',
  payload: { loading, params }
})

export const getPunchesSuccess = punches => ({
  type: 'GET_PUNCHES_SUCCESS',
  payload: punches,
})
